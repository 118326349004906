import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Blog`}</h2>
    <p>{`I can write anything I want here and it will keep on going and going and going until I add marginally better styles I can write anything I want here and it will keep on going and going and going until I add marginally better styles I can write anything I want here and it will keep on going and going and going until I add marginally better styles`}</p>
    <p>{`Bold	`}<strong parentName="p">{`bold text`}</strong></p>
    <p>{`Italic	`}<em parentName="p">{`italicized text`}</em></p>
    <p>{`Blockquote	> blockquote`}</p>
    <p>{`Ordered List	`}</p>
    <ol>
      <li parentName="ol">{`First item`}</li>
      <li parentName="ol">{`Second item`}</li>
      <li parentName="ol">{`Third item`}</li>
    </ol>
    <p>{`Unordered List	`}</p>
    <ul>
      <li parentName="ul">{`First item`}</li>
      <li parentName="ul">{`Second item`}</li>
      <li parentName="ul">{`Third item`}</li>
    </ul>
    <p>{`Code	`}<inlineCode parentName="p">{`code`}</inlineCode></p>
    <h2><span className='marginnote'>{` Here's ana saidas`}</span></h2>
    <p>{`Link	`}<a parentName="p" {...{
        "href": "https://www.example.com"
      }}>{`title`}</a></p>
    <pre><code parentName="pre" {...{}}>{`{
  "firstName": "John",
  "lastName": "Smith",
  "age": 25
}
`}</code></pre>
    <p>{`Here's a sentence with a footnote.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` `}</p>
    <p>{`Here's a sentence with another footnote. `}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <h3>{`My Great Heading {#custom-id}`}</h3>
    <p>{`term
: definition`}</p>
    <p><del parentName="p">{`The world is flat.`}</del></p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Write the press release`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Update the website`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Contact the media`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://mdg.imgix.net/assets/images/san-juan-mountains.jpg?auto=format&fit=clip&q=40&w=1080",
        "alt": "The San Juan Mountains are beautiful!",
        "title": "San Juan Mountains"
      }}></img></p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`This is the footnote.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`This is the second footnote.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      